import React from "react"
import styled from "styled-components"
import { palette, theme } from "styled-tools"

const Title = styled.h1`
  font-size: 3.2rem;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${palette("grey", 1)};
  font-family: ${theme("fontFamily.secondary")};
`

const Description = styled.h2`
  width: 60%;
  font-size: 1.6rem;
  line-height: 1.5em;
  text-align: center;
  margin: 2.4rem auto;
  letter-spacing: 0.037em;
  color: ${palette("grey", 1)};
  font-family: ${theme("fontFamily.secondary")};
`

function Heading({ title, description }) {
  return (
    <>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </>
  )
}

export default Heading
