import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import ArrowDownSVG from "../../images/arrow-down-icon.svg"
import { palette, theme } from "styled-tools"

const Wrapper = styled.div`
  height: 46rem;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ScrollButton = styled.a`
  z-index: 1;
  width: 4rem;
  height: 4rem;
  display: flex;
  bottom: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${palette("white")};
  box-shadow: ${theme("boxShadow.main")};
  transition: transform ${theme("times.3")};

  &:hover {
    transform: translateY(-5px);
  }
`

function Banner({ file }) {
  return (
    <Wrapper>
      <ScrollButton href="#content">
        <ArrowDownSVG />
      </ScrollButton>
      <ImageContainer fluid={file.childImageSharp.fluid} />
    </Wrapper>
  )
}

export default Banner
